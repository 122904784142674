<script setup lang="ts">
import { UsnType, UsnTypeDict } from "@/modules/reports"

const options = (
  Object.keys(UsnType).filter((k) => typeof UsnType[k as any] === "number") as Array<
    keyof typeof UsnType
  >
).map((key) => ({
  value: UsnType[key],
  label: UsnTypeDict[UsnType[key]]
}))
</script>

<template>
  <ASelect :options="options" placeholder="Выберите тип отчета" />
</template>
