import { defineStore } from "pinia"
import { computed, nextTick, reactive, ref, watch } from "vue"
import dayjs from "dayjs"
import { useToggle } from "@vueuse/core"

import {
  useGetReportsFilter,
  useReportDownload,
  useSaveReportsFilters,
  type ReportDownloadData,
  type ReportListElement,
  type ReportsFiltersData,
  reportsTypesGet,
  LegalType,
  ReportType
} from "@/modules/reports"

export const useReportsStore = defineStore("reports", () => {
  const formatListing = ref<Array<ReportListElement>>([])

  const reportsFilters = reactive<ReportsFiltersData>({
    dateFilter: [],
    projectsFilter: null,
    legalEntityFilter: null,
    operationFilter: null,
    orgFormFilter: LegalType.entrepreneur
  })

  const formattedFilters = computed(() => {
    return {
      date_from: reportsFilters.dateFilter[0]?.format("DD-MM-YYYY"),
      date_to: reportsFilters.dateFilter[1]?.format("DD-MM-YYYY"),
      project_ids: reportsFilters.projectsFilter,
      legal_entity_id: reportsFilters.legalEntityFilter,
      operation_categories: reportsFilters.operationFilter,
      org_form: reportsFilters.orgFormFilter
    }
  })

  const getFormatListing = async () => {
    const resData = await reportsTypesGet()
    formatListing.value = resData
      .filter((report) => report.type !== ReportType.usn_minus_expenses)
      .map((report) => {
        if (report.type === ReportType.usn) {
          return { ...report, name: "УСН" }
        }
        return report
      })
  }

  const { executeDownload, downloadReportError, isLoadingFile } = useReportDownload()

  const downloadReportUsn = async (data: ReportDownloadData) => {
    await executeDownload({
      ...formattedFilters.value,
      ...data
    })
  }

  const downloadReport = async (format: any) => {
    await executeDownload({
      type: format,
      ...formattedFilters.value
    })
  }

  const { getFilters, isGettingFilters } = useGetReportsFilter()

  const isFiltersLoaded = ref(false)
  const getReportFilters = async () => {
    const response = await getFilters()

    reportsFilters.dateFilter =
      response?.date_to && response.date_from
        ? [dayjs(response?.date_from, "DD-MM-YYYY"), dayjs(response?.date_to, "DD-MM-YYYY")]
        : []
    reportsFilters.projectsFilter = response?.project_ids || []
    reportsFilters.legalEntityFilter = response?.legal_entity_id || null
    reportsFilters.operationFilter = response?.operation_categories || []
    reportsFilters.orgFormFilter = response?.org_form || LegalType.entrepreneur
    await nextTick(() => (isFiltersLoaded.value = true))
  }

  const { executeSave } = useSaveReportsFilters()
  const saveReportFilters = async () => {
    const payload = {
      date_from: reportsFilters.dateFilter?.[0]?.format("DD-MM-YYYY") || null,
      date_to: reportsFilters.dateFilter?.[1]?.format("DD-MM-YYYY") || null,
      project_ids: reportsFilters.projectsFilter ?? [],
      legal_entity_id: reportsFilters.legalEntityFilter,
      operation_categories: reportsFilters.operationFilter ?? [],
      org_form: reportsFilters.orgFormFilter
    }
    await executeSave(payload)
  }

  const $reset = () => {
    formatListing.value = []
    Object.assign(reportsFilters, { dateFilter: [], projectsFilter: [], legalEntityFilter: null })
    isFiltersLoaded.value = false
  }

  watch(
    () => reportsFilters.legalEntityFilter,
    (val) => {
      if (val !== null && isLegalEntityEmpty) {
        toggleIsLegalEntityEmpty(false)
      }
    }
  )

  const [isLegalEntityEmpty, toggleIsLegalEntityEmpty] = useToggle(false)
  return {
    formatListing,
    reportsFilters,
    downloadReportError,
    isGettingFilters,
    isFiltersLoaded,
    isLoadingFile,
    isLegalEntityEmpty,
    downloadReport,
    downloadReportUsn,
    getReportFilters,
    saveReportFilters,
    getFormatListing,
    toggleIsLegalEntityEmpty,
    $reset
  }
})
