<script setup lang="ts">
import { theme } from "ant-design-vue"

const { token } = theme.useToken()
</script>

<template>
  <div class="example-block">
    <AText strong>Выбор данных</AText>
    <AList :style="{ color: token.colorTextSecondary, fontSize: '14px' }">
      <ol :style="{ marginBottom: 0, paddingLeft: '15px' }">
        <li>Загрузите файл и выберете из какого столбца забрать данные для создания операций.</li>
        <li>Добавьте дополнительные поля если они у вас есть.</li>
        <li>
          Если вы указали дополнительное справочное поле, например “Контрагент”, то нужно создать
          такого же контрагента и в системе, иначе эти поля будут пустыми.
        </li>
      </ol>
    </AList>
    <AText :style="{ color: token.colorTextSecondary, fontSize: '14px' }">Например:</AText>
    <ARow style="display: flex; align-items: center; max-width: 100%">
      <ACol span="9">
        <AText>Дата операции</AText>
      </ACol>
      <ACol span="15">
        <AInput value="Дата" disabled class="example-field" size="large" />
      </ACol>
    </ARow>
    <ARow style="display: flex; align-items: center; max-width: 100%">
      <ACol span="9">
        <AText>Сумма</AText>
      </ACol>
      <ACol span="15">
        <AInput value="Сумма" disabled class="example-field" size="large" />
      </ACol>
    </ARow>
  </div>
</template>

<style scoped lang="scss">
.example-block {
  border: 1px #7ab4ff solid;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
  cursor: pointer;
}

.example-field.ant-input-disabled {
  color: black;
  background-color: inherit;
}
</style>
