export interface Tariff {
  id: number
  marketing_discount: string
  period: string
  description: string
  cost: string
}

export interface Subscription {
  id: number
  name: string
  description: string
  tariffs: Array<Tariff>
}

export interface SubscriptionsData {
  success: boolean
  data: Array<Subscription>
}

export interface CurrentSubscriptionData {
  success: boolean
  data: CurrentSubscription
}

export interface CancelSubscriptionData {
  success: boolean
  message: string
}

export interface SubscriptionEditLink {
  success: boolean
  data: {
    card: string
    qr: string
  }
}

export interface CurrentSubscription {
  subscription: {
    id: number
    status: string
    start_at: string
    finish_at: string
  }
  product: {
    id: number
    name: string
    description: string
  }
  tariff: {
    id: number
    period: string
    description: string
    cost: string
  }
  payment: {
    next_payment_date: string
  }
  card_number: string
}

export enum SubscriptionPeriod {
  Trial = "P14D",
  OneMonth = "P1M",
  ThreeMonths = "P3M",
  SixMonths = "P6M",
  TwelveMonths = "P12M"
}

export enum SubscriptionNames {
  ThreeMonths = "P3M",
  SixMonths = "P6M",
  TwelveMonths = "P12M"
}

export const SubscriptionNamesDict: Record<SubscriptionNames, string> = {
  [SubscriptionNames.ThreeMonths]: "3 месяца",
  [SubscriptionNames.SixMonths]: "6 месяцев",
  [SubscriptionNames.TwelveMonths]: "12 месяцев"
}

export interface FlatSubscription extends Tariff {
  name: string
}
