<script setup lang="ts">
import { computed, h, onMounted, type PropType, ref, watch } from "vue"
import { get, set, useToggle } from "@vueuse/core"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons-vue"
import { TreeSelect } from "ant-design-vue"

import { AButton, usePopupContainerGetter } from "@/package/ui-kit"

import { CreateOperationCategoryForm } from "../CreateOperationCategoryForm"
import {
  useOperationCategoryVariantsTree,
  type OperationCategoryVariant
} from "../../hooks/use-operation-category-variants-tree"
import { operationCategoryDetailGet } from "../../api/operation-category-detail.get"

const props = defineProps({
  id: { type: Number, default: null },
  hasNoneOption: { type: Boolean, default: false },

  hideLink: {
    type: Boolean,
    default: false
  },

  exceptionId: {
    type: Number,
    default: undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  style: {
    type: Object as PropType<Record<string, string>>,
    default: undefined
  },

  inputStyle: {
    type: [String, Object] as PropType<Record<string, string>>,
    default: "width: 100%"
  },

  maxTagCount: {
    type: Number,
    default: 2
  },

  multiple: {
    type: Boolean,
    default: true
  }
})

const selectedList = defineModel({
  type: [Array, Number] as PropType<Array<number> | number>
})
const { isListingLoading, requestListing, listing, flatList } = useOperationCategoryVariantsTree()

onMounted(async () => await requestListing({ action_type: props.actionType }))

watch(listing, () => {
  if (listing?.value.length > 0 && Array.isArray(selectedList.value)) {
    const filteredSelectedIds = selectedList.value?.filter((selectedId) =>
      listing.value.find((listElement) => {
        return listElement.id === selectedId
      })
    )
    if (filteredSelectedIds?.length !== selectedList?.value?.length) {
      setTimeout(() => {
        selectedList.value = filteredSelectedIds
      }, 100)
    }
  } else {
    const find = listing.value.find((listElement) => {
      return listElement.id === (selectedList.value as number)
    })
    selectedList.value = find?.id as number
  }
})

const [isOpened, toggleOpen] = useToggle(false)

const removeCategoryById = (
  tree: OperationCategoryVariant[],
  idToRemove?: number
): OperationCategoryVariant[] => {
  if (!idToRemove) return tree
  return tree
    .filter((category) => category.id !== idToRemove)
    .map((category) => ({
      ...category,
      children: removeCategoryById(category.children, idToRemove)
    }))
}

const newListing = computed(() => {
  if (props.multiple) {
    return [
      {
        id: -1,
        name: "Выбрать все",
        disabled: true,
        checkable: false
      },
      ...removeCategoryById(listing.value, Number(props.exceptionId))
    ]
  }
  if (!props.hideLink) {
    return [
      {
        id: -1,
        name: "Создать новую статью",
        disabled: true,
        checkable: false
      },
      ...removeCategoryById(listing.value, Number(props.exceptionId))
    ]
  }
  return removeCategoryById(listing.value, Number(props.exceptionId))
})

const [isFormOpen, toggleFormOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof CreateOperationCategoryForm> | null>(null)

const createOperationCategory = () => {
  formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const openForm = () => {
  toggleOpen(false)
  toggleFormOpen(true)
}

const getContainer = (): HTMLElement | null =>
  document.querySelector(
    ".operation-category-drawer .ant-drawer-body div[data-overlayscrollbars-contents]"
  )

const selectNewLegalOperationCategory = async (newId: number) => {
  await operationCategoryDetailGet(newId)
  if (props.multiple) {
    set(selectedList, [newId])
  } else {
    set(selectedList, newId)
  }
  await requestListing({ action_type: props.actionType })
  toggleFormOpen(false)
}

defineOptions({
  inheritAttrs: false
})

const innerModel = computed({
  set: (newValue) => {
    if (Array.isArray(newValue) && newValue?.length === 1 && newValue[0] === -1) {
      return
    }
    if (props.multiple) {
      set(selectedList, newValue as Array<number>)
    } else {
      set(selectedList, newValue as number)
    }
  },
  get: () => {
    if (props.multiple && Array.isArray(selectedList.value)) {
      return selectedList.value.map((id) => {
        const r = get(flatList).get(id)
        return r?.id ?? id
      })
    } else {
      return get(selectedList)
    }
  }
})

const isAllChecked = computed(() => get(flatList).size === (selectedList.value as number[])?.length)
const checkAllAction = () => {
  const selecteableList = Array.from(get(flatList).values()).map(({ id }) => id)
  set(innerModel, selecteableList)
}
const cleanupAllAction = () => {
  set(selectedList, [])
}
const popupContainerGetter = usePopupContainerGetter()
const treeNodeFilter = (query: string, data: { id: number; name: string }) =>
  data.name.toLowerCase().includes(query.toLowerCase())

const checkAllHandler = () => {
  isAllChecked.value ? cleanupAllAction() : checkAllAction()
  toggleOpen(false)
}

const buttonIcon = computed(() => (isAllChecked.value ? h(CloseOutlined) : h(CheckOutlined)))
</script>

<template>
  <AFlex :gap="8" :style="style">
    <ATreeSelect
      v-bind="$attrs"
      v-model:value="innerModel"
      popup-class-name="operation-category-tree-select"
      :tree-line="{ showLeafIcon: false }"
      allow-clear
      :open="isOpened"
      placeholder="Выберите статью"
      size="large"
      show-search
      show-arrow
      tree-node-filter-prop="name"
      virtual
      :field-names="{ children: 'children', label: 'name', value: 'id' }"
      :filter-option="false"
      :tree-data="newListing"
      :loading="isListingLoading"
      tree-data-simple-mode
      :filter-tree-node="treeNodeFilter"
      :show-checked-strategy="TreeSelect.SHOW_ALL"
      :get-popup-container="popupContainerGetter"
      :max-tag-count="maxTagCount"
      :multiple="multiple"
      :treeCheckable="multiple"
      :style="inputStyle"
      @dropdownVisibleChange="toggleOpen"
    >
      <template #title="{ id: optionId, name }">
        <AButton
          v-if="optionId === -1 && !props.hideLink"
          type="link"
          style="padding: 0; left: 0"
          @click="openForm"
          >Создать новую статью</AButton
        >
        <AButton
          v-else-if="optionId === -1 && props.multiple"
          type="link"
          :icon="buttonIcon"
          :style="{
            backgroundColor: isAllChecked ? '#e6f2ff' : 'transparent',
            padding: isAllChecked ? '0 10px' : '0',
            transition: 'none',
            left: '0'
          }"
          @click.prevent="checkAllHandler"
          >{{ isAllChecked ? "Снять выделенное" : "Выбрать всё" }}</AButton
        >
        <span v-else>{{ name }}</span>
      </template>
      <template #tagRender="{ label }">
        <ATag style="padding: 2px; border-radius: 6px; background-color: #ededed"
          ><ATypographyText ellipsis style="max-width: 70px; font-size: 16px">{{
            label
          }}</ATypographyText></ATag
        >
      </template>
    </ATreeSelect>
    <APageDrawer
      v-if="isFormOpen"
      v-model:open="isFormOpen"
      title="Создание статьи"
      class-name="operation-category-drawer"
    >
      <CreateOperationCategoryForm
        ref="formRef"
        :get-container="getContainer"
        :action-type="actionType"
        @success="selectNewLegalOperationCategory"
      />
      <template #footer>
        <ARow :gutter="[8, 8]">
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
          </ACol>
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton
              size="large"
              block
              type="primary"
              :loading="formRef?.isCreating"
              @click="createOperationCategory"
              >Создать</AButton
            >
          </ACol>
        </ARow>
      </template>
    </APageDrawer>
  </AFlex>
</template>

<style>
.operation-category-tree-select .ant-select-tree .ant-select-tree-indent-unit,
.operation-category-tree-select .ant-select-tree .ant-select-tree-switcher-leaf-line {
  opacity: 0;
}
.ant-select-tree-checkbox {
  display: none;
}
.ant-select-tree-checkbox-checked
  + .ant-tree-select-dropdown
  .ant-select-tree
  .ant-select-tree-node-content-wrapper,
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-checkbox-checked + span,
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-checkbox-checked + span:hover {
  background-color: #e6f2ff;
}
.operation-category-tree-select .ant-select-tree-treenode-disabled .ant-select-tree-switcher {
  display: none;
}
</style>
