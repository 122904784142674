import type { Dayjs } from "dayjs"

import dayjs from "dayjs"

import { InfoModal } from "@/package/ui-kit"

export const showRepeatPeriodModal = () =>
  InfoModal({
    title: "Повторение операций",
    content: "Установить повторение для данной операции"
  })

export const showFormatModal = () =>
  InfoModal({
    title: "Поддерживаемые форматы",
    content: "pdf, doc, docx, xls, xlsx, jpeg, png, zip, rar, txt, csv, xml"
  })

export const calculateRepetitions = (period: string, endDateStr?: Dayjs, startDateStr?: Dayjs) => {
  if (!endDateStr) return 0

  const endDate = dayjs(endDateStr)
  const startDate = startDateStr ? dayjs(startDateStr) : dayjs()

  if (endDate.isBefore(startDate)) return 0

  const periodCalculations: { [key: string]: () => number } = {
    P1D: () => endDate.diff(startDate, "day"),
    P7D: () => endDate.diff(startDate, "week"),
    P14D: () => Math.floor(endDate.diff(startDate, "day") / 14),
    P1M: () => endDate.diff(startDate, "month"),
    P4M: () => Math.floor(endDate.diff(startDate, "month") / 4),
    P6M: () => Math.floor(endDate.diff(startDate, "month") / 6),
    P1Y: () => endDate.diff(startDate, "year"),
    P2Y: () => Math.floor(endDate.diff(startDate, "year") / 2),
    P5Y: () => Math.floor(endDate.diff(startDate, "year") / 5)
  }

  return periodCalculations[period]?.() || 0
}
