import type { CheckResponse } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useCheckEmail = () => {
  const { execute, data, isLoading, error } = useAxios<CheckResponse>(
    "/users/exist-email",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const checkEmail = async (email: string) => {
    const payload = {
      email
    }
    return await execute("/users/exist-email", { params: payload })
  }

  return {
    validationData: data,
    checkEmail,
    isValidating: isLoading,
    emailCheckError: useApiError(error)
  }
}
