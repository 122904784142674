<script setup lang="ts">
import { LegalType, LegalTypeDict } from "@/modules/reports"

const options = (
  Object.keys(LegalType).filter((k) => typeof LegalType[k as any] === "number") as Array<
    keyof typeof LegalType
  >
).map((key) => ({
  value: LegalType[key],
  label: LegalTypeDict[LegalType[key]]
}))
</script>

<template>
  <ASelect :options="options" size="large" />
</template>
