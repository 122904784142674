import type { AxiosResponse } from "axios"
import type { MaybeRef, Ref } from "vue"

import { AxiosError, CanceledError } from "axios"
import { computed, readonly, ref, watch } from "vue"
import { get, set } from "@vueuse/core"

import {
  ApiBadReqResponse,
  ApiForbiddenResponse,
  ApiNotFoundResponse,
  ApiPayloadTooLargeResponse,
  ApiUnprocessableEntityResponse,
  ApiFailedDependencyResponse
} from "./exception"

const UNKNOWN_ERROR_MESSAGE = "Неизвестная ошибка, пожалуйста обратитесь в поддержку."
export const useApiError = (err: MaybeRef<unknown>, unknownErrorMessage = UNKNOWN_ERROR_MESSAGE) =>
  computed(() => {
    let message = null
    let status = null
    const error = get(err)
    if (error) {
      if (error instanceof ApiBadReqResponse) {
        status = error.STATUS
        message = error.message
      } else if (error instanceof ApiNotFoundResponse) {
        status = error.STATUS
        message = error.message
      } else if (error instanceof ApiForbiddenResponse) {
        status = error.STATUS
        message = error.message ?? "Данное действие недоступно"
      } else if (error instanceof ApiPayloadTooLargeResponse) {
        status = error.STATUS
        message = error.message
      } else if (error instanceof ApiUnprocessableEntityResponse) {
        status = error.STATUS
        message = error.message
      } else if (error instanceof ApiFailedDependencyResponse) {
        status = error.STATUS
        message = error.message
      } else if (!(error instanceof CanceledError)) {
        message = unknownErrorMessage
      }
    }
    return status === null && message === null ? null : { status, message }
  })

export const useApiResponseStatus = (
  response: Ref<AxiosResponse<unknown> | undefined>,
  error: Ref<unknown>
) => {
  const status = ref<number | null>(null)

  watch([response, error], ([resp, err]) => {
    let result = null
    if (err) {
      set(response, undefined)
      if (err instanceof ApiBadReqResponse) {
        result = err.STATUS
      } else if (err instanceof ApiNotFoundResponse) {
        result = err.STATUS
      } else if (err instanceof ApiForbiddenResponse) {
        result = err.STATUS
      } else if (err instanceof ApiPayloadTooLargeResponse) {
        result = err.STATUS
      } else if (err instanceof ApiUnprocessableEntityResponse) {
        result = err.STATUS
      } else if (err instanceof AxiosError) {
        result = (err as any)?.response?.status ?? null
      }
    } else {
      result = resp?.status ?? null
    }
    set(status, result)
  })

  return readonly(status)
}
