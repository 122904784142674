<script setup lang="ts">
import { computed, watch, type PropType } from "vue"

import { useLegalNameList } from "../hooks/use-legal-entity-list-name"

const props = defineProps({
  hideLink: {
    type: Boolean,
    default: false
  },

  disabled: {
    type: Boolean,
    default: false
  },

  autoInsert: {
    type: Boolean,
    default: false
  }
})

const [selectedId, modifiers] = defineModel({
  type: [Number, null] as PropType<number | null | undefined>,
  get(v) {
    return v
  },
  set(v) {
    let result = v
    if (modifiers["null-on-empty"]) {
      result = v === undefined ? null : v
    }
    return result
  }
})

const { legalNameListResponse } = useLegalNameList()

const options = computed(() =>
  legalNameListResponse.value?.map((role) => ({ label: role.organization_name, value: role.id }))
)

const nodeFilter = (query: string, data: { label: string; value: number }) =>
  data.label.toLowerCase().includes(query.toLowerCase())

const isDisabled = computed(() => props.disabled)

const autoCheckOneValListing = (
  data: {
    label: string
    value: number
  }[]
) => {
  if (props.autoInsert && data.length === 1) {
    selectedId.value = data[0].value
  }
}

watch(
  () => props.disabled,
  (val) => {
    if (!val) {
      autoCheckOneValListing(options.value || [])
    }
  }
)

watch(
  () => options.value,
  (newValue) => {
    if (newValue) {
      if (!props.disabled) {
        autoCheckOneValListing(newValue)
      }

      if (selectedId.value) {
        const isValid = newValue.some((option) => option.value === selectedId.value)
        if (!isValid) {
          selectedId.value = null
        }
      }
    }
  }
)
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    :options="options"
    size="large"
    show-search
    allow-clear
    virtual
    :filter-option="nodeFilter"
    :disabled="isDisabled"
    placeholder="Выберите юр.лицо"
  />
</template>
