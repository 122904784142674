import type { CreateTransferOperationForm } from "../interface"

import { computed, inject, reactive } from "vue"
import { get } from "@vueuse/core"

import { OperationAction, OperationType, type OperationCreatRequest } from "@/modules/operation"
import { parseFloatNumber } from "@/package/util"

import { TRANSFER_FORM_DEFAULT_STATE } from "../constants"

import { FormStateToken } from "./use-form-state-provider"

export const useTransferRuleset = () =>
  computed(() => ({
    amount: [{ required: true, message: "Пожалуйста, введите сумму", trigger: "submit" }],
    date: [{ required: true, message: "Пожалуйста, выберите дату", trigger: "submit" }],
    bank_account_from_id: [
      { required: true, message: "Пожалуйста, выберите счет отправителя", trigger: "submit" }
    ],
    bank_account_to_id: [
      { required: true, message: "Пожалуйста, выберите счет получателя", trigger: "submit" }
    ],
    purpose: [
      {
        type: "string",
        max: 4096,
        message: "Превышено допустимое количество символов"
      }
    ]
  }))
export const useTransferFormStateInject = () => {
  const formState = inject<Partial<CreateTransferOperationForm>>(
    FormStateToken,
    reactive(Object.assign({}, TRANSFER_FORM_DEFAULT_STATE))
  )

  const formData = computed((): OperationCreatRequest => {
    const {
      amount: amountSrc,
      date,
      tags,
      purpose,
      bank_account_from_id,
      bank_account_to_id,
      files,
      transactions
    } = formState as CreateTransferOperationForm
    const amount = parseFloatNumber(amountSrc)
    const idTransaction = transactions.length > 0 ? transactions[0].id : undefined
    return {
      action: OperationAction.transfer,
      type: OperationType.factual,
      transactions: [
        {
          id: idTransaction,
          amount
        }
      ],
      amount,
      date: date?.add(date?.utcOffset(), "minutes"),
      tags,
      purpose,
      bank_account_from_id,
      bank_account_to_id,
      files: get(files)?.map((file) => file.id)
    }
  })

  return {
    formData,
    formState
  }
}
