<script setup lang="ts">
import { h, onUnmounted, watch } from "vue"
import {
  DeleteOutlined,
  InboxOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons-vue"
import { Form, theme } from "ant-design-vue"
import { noop, useToggle } from "@vueuse/core"
import { push } from "notivue"

import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { BankAccountTreeSelect } from "@/modules/bank-account"
import { useFileDownload } from "@/modules/operation"
import { InfoModal } from "@/package/ui-kit"

import { useRuleset, useFileUpload } from "./hooks"
import { useOperationImportStore } from "./store"
import ImportExample from "./ImportExample.vue"

interface SelectOption {
  value: string
  label: string
}

const { executeDownload, downloadFileError } = useFileDownload()

const { token } = theme.useToken()

const store = useOperationImportStore()
const { ruleset } = useRuleset(store.formFields)

const { validate, validateInfos } = Form.useForm({ fields: null }, ruleset)

const { upload, uploadError } = useFileUpload()

watch(uploadError, (err) => {
  if (err) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
watch(downloadFileError, (err) => {
  if (err?.message) {
    push.error({ message: err.message })
  }
})

const submitAction = () => {
  validate()
    .then(() => store.importOperations())
    .catch(noop)

  if (
    (store.formFields[0].value === null && validateInfos.fields.validateStatus === "error") ||
    (store.formFields[0].value === null && validateInfos.fields.validateStatus === "validating")
  ) {
    validateInfos.bank_account_id.help = ["Пожалуйста, выберите счет"]
    validateInfos.bank_account_id.validateStatus = "error"
  } else {
    validateInfos.bank_account_id.help = [""]
    validateInfos.bank_account_id.validateStatus = "success"
  }
}

defineExpose({ submitAction })

const [isOpenHint, toggleHint] = useToggle(false)

const toggleSplitAction = (type: boolean) => {
  toggleHint(type)
}

const showAnnotationModal = (title: string, content: string) =>
  InfoModal({ content: content, title: title })

onUnmounted(store.$reset)
</script>

<template>
  <AForm
    name="create-product-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ padding: '24px' }"
    scroll-to-first-error
  >
    <AUploadDragger :file-list="store.files" name="file" :multiple="false" :custom-request="upload">
      <p class="ant-upload-drag-icon" style="height: 32px">
        <InboxOutlined style="width: 32px; height: 32px" />
      </p>
      <p class="ant-upload-text">Нажмите или перетащите файл для загрузки</p>
      <AText type="secondary">Загрузите файл в формате .xlsx, .xls</AText>
      <template #itemRender="{ file }">
        <AFlex style="width: 100%; display: flex; justify-content: space-between">
          <AFlex :style="{ maxWidth: '90%' }">
            <PaperClipOutlined />
            <AButton
              class="download-button"
              type="link"
              @click="() => executeDownload(file.id, file.name)"
              >{{ file.name }}</AButton
            >
          </AFlex>
          <AButton :icon="h(DeleteOutlined)" type="text" @click="store.removeFile" />
        </AFlex>
      </template>
    </AUploadDragger>
    <AButton
      v-if="!isOpenHint"
      :icon="h(QuestionCircleOutlined)"
      size="small"
      type="link"
      style="margin-top: 16px"
      @click="toggleSplitAction(true)"
    >
      Выбор данных
    </AButton>
    <ImportExample v-else @click="toggleSplitAction(false)" />
    <template v-for="formField in store.formFields" :key="formField.id">
      <AInputGroup compact style="margin-top: 16px">
        <ARow v-if="formField.operationKey?.value === 'bank_account_id'" style="width: 100%">
          <AFormItem
            :label="formField.operationKey.label"
            name="bank_account_id"
            :style="{ marginBottom: '-13px' }"
            v-bind="validateInfos?.bank_account_id"
          >
            <BankAccountTreeSelect
              v-model="formField.value"
              size="large"
              :style="{ width: '100%' }"
              placeholder="Выберите счет"
              :disabled="store.excelColumnOptionList.length === 0"
              hide-link
            />
          </AFormItem>
        </ARow>
        <ARow v-else style="display: flex; align-items: center; max-width: 100%">
          <ACol span="10">
            <AFlex v-if="formField.operationKey?.label" :gap="8" align="center">
              <AText
                ><ATypographyText type="danger">*</ATypographyText>
                {{ formField.operationKey?.label }}
              </AText>
              <AButton
                v-if="store.hintsList[formField.operationKey.value]"
                size="small"
                type="link"
                :icon="h(QuestionCircleOutlined)"
                :style="{ color: token.colorPrimary }"
                @click="
                  showAnnotationModal(
                    formField.operationKey?.label,
                    store.hintsList[formField.operationKey.value]
                  )
                "
              />
            </AFlex>

            <ASelect
              v-else
              placeholder="Выберите поле"
              size="large"
              :options="store.filteredFieldNameOptions"
              style="width: 90%"
              @select="
                (value: string, option: SelectOption) => {
                  formField.operationKey = { label: option.label, value: value }
                }
              "
            />
          </ACol>
          <ACol span="14">
            <ASelect
              v-model:value="formField.value"
              :style="{ width: formField.id <= 3 ? '100%' : 'calc(100% - 56px)' }"
              :options="store.filteredExcelColumnOptions"
              :disabled="store.excelColumnOptionList.length === 0"
              placeholder="Выберите название столбца"
              size="large"
            />
            <AButton
              v-if="formField.id > 3"
              :style="{ marginLeft: '16px', width: '40px' }"
              size="large"
              type="primary"
              danger
              :icon="h(DeleteOutlined)"
              @click="() => store.deleteField(formField.id)"
            />
          </ACol>
        </ARow>
      </AInputGroup>
    </template>
    <AButton
      v-if="store.canAddMoreFields"
      type="text"
      :style="{ color: token.colorPrimary, padding: 0 }"
      @click="store.addField"
      >Добавить поле</AButton
    >
    <template
      v-if="
        validateInfos.fields.validateStatus === 'error' ||
        validateInfos.fields.validateStatus === 'validating'
      "
    >
      <div class="form-fields-global-error">Заполните все добавленные поля</div>
    </template>
  </AForm>
</template>

<style scoped lang="scss">
:deep(.ant-checkbox-group) {
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.hidden-upload:deep(.ant-upload) {
  display: none;
}

.form-fields-global-error {
  text-align: right;
  margin-top: 8px;
  gap: 8px;
  justify-items: end;
  color: v-bind("token.colorErrorText");
}

.download-button {
  width: 100%;
}

:deep(.download-button span) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
