<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs"
import { computed, onMounted, type PropType, reactive, ref, watch } from "vue"
import { Form, Divider, theme } from "ant-design-vue"
import { InfoCircleOutlined } from "@ant-design/icons-vue"
import { get, noop, useDebounceFn } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { useGetSubscriptionTypes, type UserDetail, type UserDetailFields } from "@/modules/admin"
import { useForm } from "@/package/hooks"
import { injectProfile, UserRole } from "@/modules/profile"
import {
  gridGutter,
  usePopupContainerGetter,
  DATETIME_MASK,
  DATETIME_DOTS_MASK
} from "@/package/ui-kit"

import { useRuleset } from "../hooks"

import UserStatusSelect from "./UserStatusSelect.vue"
import UserEmailStatusSelect from "./UserEmailStatusSelect.vue"
import UserPasswordStatusSelect from "./UserPasswordStatusSelect.vue"
import UserRoleSelect from "./UserRoleSelect.vue"
import UserSubscriptionTypeSelect from "./UserSubscriptionTypeSelect.vue"

const props = defineProps({
  user: {
    type: [Object, undefined] as PropType<UserDetail | undefined>,
    default: () => undefined
  }
})

const emit = defineEmits(["submit", "isSubscriptionsLoaded"])
const token = theme.useToken()

const popupContainerGetter = usePopupContainerGetter()
const emptyPatronymic = ref(false)

const userId = useRouteParams<string>("id")

const { subscriptionsData, getSubscriptions, SubscriptionsFinishedLoad } = useGetSubscriptionTypes()

const tariffList = computed(() => {
  if (subscriptionsData) {
    return subscriptionsData?.value?.data
      ?.map((product) =>
        product.tariffs.map((tariff) => ({
          name: product.name,
          id: tariff.id,
          period: tariff.period
        }))
      )
      .reverse()
      .flat()
  }
  return []
})

onMounted(async () => {
  await getSubscriptions()
})
watch(
  SubscriptionsFinishedLoad,
  () => {
    emit("isSubscriptionsLoaded")
  },
  { deep: true }
)

const tariffFinishAt = computed(() => {
  if (!props.user) return
  return props.user.subscription?.tariff_finish_at
})

const isTrial = computed(() => {
  if (!props.user) return
  return props.user.subscription?.trial_period
})

const isFreeTariff = computed(() => {
  return formState.tariff === "FREE"
})

const formState = reactive<UserDetailFields>({
  subscription_start_at: "",
  first_name: "",
  last_name: "",
  middle_name: null,
  email: "",
  role: null,
  status: null,
  status_email: null,
  status_pass: null,
  payment: null,
  tariff: "",
  transfer_payment: false,
  subscription_finish_at: null
})

const isChangeTariffBlock = ref(false)

const resetAction = useDebounceFn(() => {
  if (props.user) {
    const {
      last_name,
      first_name,
      middle_name,
      email,
      role,
      status,
      status_email,
      status_pass,
      subscription
    } = props?.user || {}
    emptyPatronymic.value = middle_name === null || middle_name === ""

    const formattedFormState = {
      last_name,
      first_name,
      middle_name: emptyPatronymic.value ? null : middle_name,
      email,
      role,
      status,
      status_email,
      status_pass,
      transfer_payment: false,
      payment: subscription?.last_payment?.amount ?? null,
      subscription_start_at: ref<Dayjs | null>(
        subscription?.tariff_start_at ? dayjs(subscription?.tariff_start_at, DATETIME_MASK) : null
      ),
      subscription_finish_at: ref<Dayjs | null>(
        subscription?.tariff_finish_at ? dayjs(subscription?.tariff_finish_at, DATETIME_MASK) : null
      ),
      tariff: subscription?.trial_period
        ? "PRO"
        : tariffList.value?.find((el) => el.id === subscription?.tariff)?.name
    }

    Object.assign(formState, formattedFormState)
  }
}, 100)
onMounted(resetAction)
watch(() => props.user, resetAction)

const allPayment = computed(() => {
  if (!props.user) return "0 ₽"
  if (formState.payment === props.user.subscription?.last_payment?.amount)
    return `${props.user.subscription?.payment ?? 0} ₽`
  return `${(props.user.subscription?.payment ?? 0) + (formState.payment ?? 0)} ₽`
})

const ruleset = useRuleset()
const profile = injectProfile()

const { validate, validateInfos, clearValidate } = useForm(formState, ruleset)

const hasPermission = computed(
  () => profile.value?.role !== UserRole.Admin || profile.value.id === +userId
)

const requestData = computed(() => {
  const currTariff =
    formState?.tariff === "PRO"
      ? tariffList?.value?.find((item) => item.period === "P3M")?.id
      : tariffList?.value?.find((item) => item.name === "FREE")?.id
  return {
    ...formState,
    payment:
      formState.payment === props?.user?.subscription?.last_payment?.amount
        ? undefined
        : formState.payment,
    subscription_start_at: formState.subscription_start_at
      ? dayjs(formState.subscription_start_at).format(DATETIME_MASK)
      : null,
    subscription_finish_at: formState.subscription_finish_at
      ? dayjs(formState.subscription_finish_at).format(DATETIME_MASK)
      : null,
    tariff: currTariff,
    update_subscription: isChangeTariffBlock.value
  }
})

const submitAction = () => {
  const { trial_period, tariff, tariff_start_at, tariff_finish_at, last_payment } =
    props?.user?.subscription || {}
  const oldTariff = trial_period ? "PRO" : tariffList.value?.find((el) => el.id === tariff)?.name
  const isChangeTariff = formState.tariff !== oldTariff

  const isChangeDateStart = tariff_start_at
    ? dayjs(formState.subscription_start_at).format(DATETIME_DOTS_MASK) !== tariff_start_at
    : !!formState.subscription_start_at

  const isChangeDateEnd = tariff_finish_at
    ? dayjs(formState.subscription_finish_at).format(DATETIME_DOTS_MASK) !== tariff_finish_at
    : !!formState.subscription_finish_at

  const isChangePayment = formState.payment != last_payment?.amount

  const allCheck = [isChangeTariff, isChangePayment, isChangeDateStart, isChangeDateEnd]

  if (!isChangeTariffBlock.value) {
    if (allCheck.includes(true) || formState.transfer_payment) {
      push.error({
        message:
          "Вы хотите изменить данные о подписке, активируйте чек-бокс 'Я изменил данные по подписке'"
      })
      return
    }
  }
  validate()
    .then(() => emit("submit", get(requestData.value)))
    .catch(noop)
}

watch(emptyPatronymic, (val) => {
  if (val) {
    clearValidate("middle_name")
    formState.middle_name = null
  }
})

defineExpose({ submitAction })
</script>

<template>
  <Form
    name="admin-edit-user-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <AText sm :style="{ fontWeight: 700 }">Персональные данные</AText>
    <AFormItem
      label="Фамилия"
      name="last_name"
      v-bind="validateInfos?.last_name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.last_name" size="large" placeholder="Введите фамилию" />
    </AFormItem>
    <AFormItem label="Имя" name="first_name" v-bind="validateInfos?.first_name">
      <AInput v-model="formState.first_name" size="large" placeholder="Введите имя" />
    </AFormItem>
    <AFormItem label="Отчество" name="middle_name" v-bind="validateInfos?.middle_name">
      <ARow :gutter="gridGutter" :wrap="false">
        <ACol flex="auto">
          <AInput
            v-model.trim="formState.middle_name"
            size="large"
            placeholder="Введите отчество"
            :disabled="emptyPatronymic"
          />
        </ACol>
        <ACol :style="{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }">
          <AFormItemRest>
            <ACheckbox v-model:checked="emptyPatronymic" :style="{ whiteSpace: 'nowrap' }">
              Нет отчества
            </ACheckbox>
          </AFormItemRest>
        </ACol>
      </ARow>
    </AFormItem>
    <AFormItem label="E-mail" name="email" v-bind="validateInfos?.email">
      <AInput
        v-model="formState.email"
        size="large"
        placeholder="Введите e-mail"
        @change="
          () => {
            if (validateInfos?.email.validateStatus === 'error') {
              validate('email', { trigger: 'blur' }).catch(noop)
            }
          }
        "
        @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>
    <Divider :style="{ marginBottom: '16px', borderBlockStartWidth: '2px' }" />
    <AText sm :style="{ fontWeight: 700 }">Общее</AText>
    <AFormItem label="GUID" name="id" :style="{ marginTop: '16px' }">
      <AInput v-model="userId" size="large" disabled />
    </AFormItem>
    <Divider :style="{ marginBottom: '16px', borderBlockStartWidth: '2px' }" />
    <AText sm :style="{ fontWeight: 700 }">Данные пользователя</AText>
    <AFormItem label="Роль" name="role" v-bind="validateInfos?.role" :style="{ marginTop: '16px' }">
      <UserRoleSelect
        v-model:value="formState.role"
        size="large"
        :disabled="hasPermission"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Статус" name="status" v-bind="validateInfos?.status">
      <UserStatusSelect
        v-model:value="formState.status"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem
      label="Статус подтверждения почты"
      name="status_email"
      v-bind="validateInfos?.status_email"
    >
      <UserEmailStatusSelect
        v-model:value="formState.status_email"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem
      label="Статус блокировки пароля"
      name="status_email"
      v-bind="validateInfos?.status_pass"
    >
      <UserPasswordStatusSelect
        v-model:value="formState.status_pass"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <Divider :style="{ marginBottom: '16px', borderBlockStartWidth: '2px' }" />
    <AText sm :style="{ fontWeight: 700 }">Данные подписки</AText>
    <AFormItem label="Тариф" name="subscribe_type" :style="{ marginTop: '16px' }">
      <UserSubscriptionTypeSelect
        v-model:value="formState.tariff"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Сумма последнего платежа, руб">
      <AInputNumber
        v-model:value="formState.payment"
        :disabled="isFreeTariff"
        size="large"
        placeholder="Введите сумму"
      />
    </AFormItem>
    <AFormItem label="Дата последнего платежа">
      <ADatePicker
        v-model:value="formState.subscription_start_at"
        show-time
        :format="DATETIME_MASK"
        :disabled="isFreeTariff"
        size="large"
        placeholder="Выберите дату и время"
        :style="{ width: '100%', height: '40px' }"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem :label="isFreeTariff ? 'Дата окончания подписки' : 'Дата следующего платежа'">
      <ADatePicker
        v-model:value="formState.subscription_finish_at"
        show-time
        :format="DATETIME_MASK"
        :disabled="isFreeTariff"
        size="large"
        placeholder="Выберите дату и время"
        :style="{ width: '100%', height: '40px' }"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFlex vertical :style="{ maxWidth: '100%', marginTop: '28px', marginBottom: '28px' }">
      <AText sm :style="{ color: token.token.value.colorTextSecondary }"
        >Общая сумма платежей</AText
      >
      <ATypographyText>{{ allPayment }}</ATypographyText>
    </AFlex>
    <AFlex vertical :style="{ maxWidth: '100%', marginTop: '28px', marginBottom: '28px' }">
      <AText sm :style="{ color: token.token.value.colorTextSecondary }">Пробный период</AText>
      <ATypographyText v-if="isTrial">Активен до {{ tariffFinishAt }}</ATypographyText>
      <ATypographyText v-else>Неактивен</ATypographyText>
    </AFlex>
    <AFormItem>
      <ACheckbox v-model:checked="formState.transfer_payment">Провести платеж в КД</ACheckbox>
    </AFormItem>
    <ATypographyText type="secondary"
      ><InfoCircleOutlined :style="{ marginRight: '8px' }" />Данный чек-бокс необходимо выставить,
      если пользователь оплатил подписку, но данные не поступили в кабинет дистрибьютера и в КОР.
    </ATypographyText>
    <ADivider :style="{ marginTop: '24px', marginBottom: '28px' }" />
    <AFormItem>
      <ACheckbox v-model:checked="isChangeTariffBlock">Я изменил данные по подписке</ACheckbox>
    </AFormItem>
  </Form>
</template>
