import { computed } from "vue"

import { digitsIncludeValidator } from "@/package/validators"

export const useRuleset = () =>
  computed(() => ({
    usnType: [
      {
        required: true,
        message: "Необходимо указать тип отчёта"
      }
    ],
    tax: [
      {
        required: true,
        message: "Необходимо указать код применения налоговой ставки"
      }
    ],
    quarter: [
      {
        required: true,
        message: "Необходимо указать налоговую ставку за первый квартал"
      },
      {
        asyncValidator: digitsIncludeValidator
      }
    ],
    year: [
      {
        required: true,
        message: "Необходимо указать налоговую ставку за налоговый период"
      },
      {
        asyncValidator: digitsIncludeValidator
      }
    ],
    nineMonths: [
      {
        required: true,
        message: "Необходимо указать налоговую ставку за девять месяцев"
      },
      {
        asyncValidator: digitsIncludeValidator
      }
    ],
    halfYear: [
      {
        required: true,
        message: "Необходимо указать налоговую ставку за полугодие"
      },
      {
        asyncValidator: digitsIncludeValidator
      }
    ],
    loss: [
      {
        asyncValidator: digitsIncludeValidator
      }
    ]
  }))
