<script setup lang="ts">
import { computed } from "vue"

import { injectProfileLoading } from "@/modules/profile"
import router, { Subscription } from "@/router"

import SubscriptionBadge from "../SubscriptionBadge/SubscriptionBadge.vue"

const props = defineProps({
  subscription: {
    type: String,
    default: null
  },

  subscriptionLoading: {
    type: Boolean,
    default: false
  }
})

defineEmits(["editProfile"])

const isLoading = injectProfileLoading()
defineOptions({
  inheritAttrs: true
})

const headerTitle = computed(() =>
  props.subscription === "PRO" ? "Вы используете все преимущества" : "Использовать все преимущества"
)

const buttonTitle = computed(() =>
  props.subscription === "PRO" ? "Другие варианты подписок" : "Подключить"
)
</script>

<template>
  <ASpin :spinning="isLoading || props.subscriptionLoading">
    <div class="wrapper">
      <AFlex class="bottom-border header" align="center">
        <ATypographyText block :style="{ fontSize: '26px', marginRight: '12px' }">{{
          headerTitle
        }}</ATypographyText>
        <SubscriptionBadge size="large" subscription="PRO" />
      </AFlex>
      <ul class="benefits">
        <li>Подключение банков и импорт операций</li>
        <li>Работа с планированием и бюджетами</li>
        <li>Формирование отчётов</li>
      </ul>
      <AButton
        block
        type="primary"
        size="large"
        @click="() => router.push({ name: Subscription })"
        >{{ buttonTitle }}</AButton
      >
    </div>
  </ASpin>
</template>

<style scoped>
.wrapper {
  border: 1px solid #0057ff;
  padding: 24px 24px 32px 24px;
  border-radius: 6px;
}

.header {
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.benefits {
  list-style: none;
  margin-bottom: 32px;
  padding-left: 16px;
  position: relative;
}

.benefits:before {
  content: "";
  display: block;
  height: 100%;
  width: 4px;
  background: #b7eb8f;
  position: absolute;
  left: 0;
}

.benefits > li:not(:last-child) {
  margin-bottom: 12px;
}
</style>
