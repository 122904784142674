import type { AxiosInstance, AxiosResponse } from "axios"
import type { ApiBadRequestMessage, ApiNotFoundRequestMessage } from "./responses"

import {
  type ApiErrorResponse,
  ApiFailedDependencyResponse,
  ApiPayloadTooLargeResponse,
  ApiUnprocessableEntityResponse
} from "./exception"
import {
  ApiBadReqResponse,
  ApiForbiddenResponse,
  ApiNotFoundResponse,
  isApiError
} from "./exception"

export const setupErrorInterceptor = (client: AxiosInstance) => {
  client.interceptors.response.use(
    (data) => data,
    (error) => {
      let result = error
      if (isApiError(error) && error.response) {
        const { status: defaultStatus, data } = error.response as AxiosResponse<ApiErrorResponse>
        const status = defaultStatus ?? error.response.status
        switch (status) {
          case 424: {
            const {
              message
              //@ts-ignore
            } = data as { message: string }
            result = new ApiFailedDependencyResponse(message)
            break
          }
          case 422: {
            const {
              message
              //@ts-ignore
            } = data as { message: string }
            result = new ApiUnprocessableEntityResponse(message)
            break
          }
          case 400: {
            const {
              message,
              Message,
              errors,
              data: errorArray
              //@ts-ignore
            } = data as ApiBadRequestMessage & { Message: string }
            result = new ApiBadReqResponse(message ?? Message, errors, errorArray)
            break
          }
          case 413: {
            const {
              message,
              Message,
              errors
              //@ts-ignore
            } = data as ApiBadRequestMessage & { Message: string }
            result = new ApiPayloadTooLargeResponse(message ?? Message, errors)
            break
          }
          case 403: {
            // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
            const { message, success, ...payload } = data
            result = new ApiForbiddenResponse(message, payload)
            break
          }
          case 404: {
            if (error.response.data instanceof Blob) {
              new Response(error.response.data).json().then((errorData) => {
                const { message } = errorData
                result = new ApiNotFoundResponse(message)
              })
              break
            }
            const { message } = data as ApiNotFoundRequestMessage
            result = new ApiNotFoundResponse(message)
            break
          }
        }
      }
      return Promise.reject(result)
    }
  )
}
