<script setup lang="ts">
import { useRouter } from "vue-router"
import { reactive, ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { Form } from "ant-design-vue"
import { push } from "notivue"

import { injectProfileRefresh } from "@/modules/profile"
import { PhysProfileRouteName } from "@/router"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import ChangePasswordForm from "../../../modules/profile/components/ChangePasswordForm.vue"
import { useConfirmChangingPassword } from "../../../modules/profile/hooks/use-confirm-change-password"

interface FormState {
  code: string
}

const router = useRouter()

const [isOpened, toggleOpened] = useToggle(true)
const [isModalOpened, toggleModalOpened] = useToggle(false)
const formRef = ref<InstanceType<typeof ChangePasswordForm> | null>(null)
const codeFormRef = ref<typeof Form | null>(null)

const refreshAsync = injectProfileRefresh()

const { confirmChangingPassword, isLoading, error } = useConfirmChangingPassword()

const onSuccess = async () => {
  setTimeout(() => toggleModalOpened(true), 500)
}

const formState = reactive<FormState>({
  code: ""
})

const rules = {
  code: [
    { required: true, message: "Заполните поле" },
    {
      min: 5,
      message: "Заполните поле"
    }
  ]
}

const onSubmit = async () => {
  if (codeFormRef.value) {
    await codeFormRef.value.validate()
    await confirmChangingPassword(formState, () => {
      codeFormRef.value?.resetFields()
      toggleModalOpened(false)
      toggleOpened(false)
      push.success({
        message: "Пароль успешно изменён"
      })
    })
    await refreshAsync()
  }
}

watch(error, (err) => {
  if (err) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Настройки безопасности"
    @onClose="() => router.push({ name: PhysProfileRouteName })"
  >
    <ChangePasswordForm ref="formRef" @success="onSuccess" />

    <template #footer>
      <ARow>
        <ACol span="24">
          <AButton block type="primary" size="large" @click="formRef?.submitAction">
            Сохранить изменения
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>

  <AModal v-model:open="isModalOpened" centered width="220px">
    <template #title>
      <ATypographyTitle :style="{ marginBottom: '20px' }" :level="4">
        Введите код подтверждения, который был отправлен на почту.
      </ATypographyTitle>
    </template>
    <template #footer>
      <AButton type="primary" block size="large" :loading="isLoading" @click.prevent="onSubmit"
        >Подтвердить</AButton
      >
    </template>
    <AForm
      ref="codeFormRef"
      :model="formState"
      name="set-code"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      hide-required-mark
      :rules="rules"
    >
      <AFormItem name="code">
        <AInput v-model:value="formState.code" size="large" :maxlength="5" />
      </AFormItem>
    </AForm>
  </AModal>
</template>
