<script setup lang="ts">
import type { FlatSubscription } from "../modules/subscription"

import { onMounted, onUnmounted, ref, watch, computed } from "vue"
import { push } from "notivue"
import { theme, Divider } from "ant-design-vue"
import { CloseCircleOutlined, CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons-vue"
import { useToggle } from "@vueuse/core"

import { formattedDate } from "@/package/util"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { injectProfile, useProfile, SubscriptionBadge } from "@/modules/profile"
import {
  useGetCurrentSubscription,
  useGetSubscriptionTypes,
  usePaySubscription,
  usePaySubscriptionApprove,
  useCancelSubscription,
  SubscriptionNamesDict,
  SubscriptionNames
} from "@/modules/subscription"
import { SubscriptionReminder } from "@/modules/subscription-reminder"
import SbpIcon from "@/assets/icons/sbp.svg"
import { usePaymentSubscriptionsBus } from "@/modules/centrifugo"
import { useAdoptable } from "@/package/ui-kit"

import ListBlock from "../modules/subscription/components/ListBlock.vue"
const { refreshAsync } = useProfile()
const { token } = theme.useToken()

const subscriptions = ref<null | undefined | Array<FlatSubscription>>(null)
const open = ref(false)
const openConfirm = ref(false)
const modalSub = ref<undefined | string>(undefined)
const selectedSubscription = ref<null | FlatSubscription>(null)
const selectedSubscriptionId = ref<null | number>(null)
const { subscriptionsData, getSubscriptions, SubscriptionsPending, SubscriptionsError } =
  useGetSubscriptionTypes()

const {
  paymentLink,
  getSubscriptionPaymentLink,
  closeAfterApprove,
  paymentLinkPending,
  SubscriptionPaymentError
} = usePaySubscription()

const { subscriptionApprove, requestSubscriptionApprove, SubscriptionApproveError } =
  usePaySubscriptionApprove()

const { cancelSubscription, CancelSubscriptionError } = useCancelSubscription()

const profile = injectProfile()

const {
  subscriptionCurrentData,
  getCurrentSubscription,
  SubscriptionCurrentPending,
  SubscriptionCurrentError
} = useGetCurrentSubscription()

onMounted(async () => {
  await getCurrentSubscription()
})

onMounted(async () => {
  await getSubscriptions()
  if (subscriptionsData) {
    subscriptions.value = subscriptionsData?.value?.data
      .map((product) =>
        product.tariffs.map((tariff) => ({
          name: product.name,
          cost: tariff.cost,
          description: tariff.description,
          id: tariff.id,
          marketing_discount: tariff.marketing_discount,
          period: SubscriptionNamesDict[tariff.period as SubscriptionNames]
        }))
      )
      .reverse()
      .flat()
      .filter((item) => item.name !== "FREE")
  }
})

watch(
  () => subscriptions.value,
  (value) => {
    const sixMonthTariff = value?.find((item) => item.period === SubscriptionNamesDict.P6M)
    if (!sixMonthTariff) return
    selectedSubscription.value = sixMonthTariff
    selectedSubscriptionId.value = sixMonthTariff?.id
  }
)

watch(
  () => selectedSubscriptionId.value,
  (value) => {
    const tariff = subscriptions.value?.find((item) => item.id === value)
    if (!tariff) return
    selectedSubscription.value = tariff
  }
)

watch(SubscriptionsError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(SubscriptionCurrentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(SubscriptionPaymentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
watch(SubscriptionApproveError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(CancelSubscriptionError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

let paymentWindow: Window | null = null
const paymentSubscriptionsBus = usePaymentSubscriptionsBus()
paymentSubscriptionsBus.on(async (event) => {
  if (event === "success") {
    await getCurrentSubscription()

    if (paymentWindow) {
      paymentWindow.close()
      paymentWindow = null
    }
    closeAfterApprove(open)
    await refreshAsync()
  }
})

const list = [
  "Работа с физическими лицами",
  "Работа с реестром операций",
  "Работа с аналитикой",
  "Работа со счетами",
  "Работа с проектами",
  "Статьи доходов и расходов",
  "Подключение банков",
  "Импорт выгрузок",
  "Работа с планированием и бюджетами",
  "Работа с отчетами",
  "Работа с контрагентами",
  "Работа с товарами и услугами"
]

const isChangingOnFree = computed(
  () =>
    selectedSubscription?.value?.name === "FREE" &&
    subscriptionCurrentData?.value?.data?.product?.name === "PRO"
)
const isCanPayment = computed(
  () =>
    profile.value?.is_trial ||
    profile.value?.subscription === "FREE" ||
    !profile.value?.subscription
)
const finishDate = computed(() =>
  formattedDate(subscriptionCurrentData.value?.data?.subscription?.finish_at)
)
const isFinish = computed(() => !profile?.value?.is_recurrent)

const openModal = (subscription: "FREE" | "PRO") => {
  modalSub.value = subscription
  open.value = true
}

const openConfirmModal = () => {
  openConfirm.value = true
}

const changeSubscription = async () => {
  await cancelSubscription()
  push.success({ message: "Подписка отменена" })
  openConfirm.value = false
  await getCurrentSubscription()
  await refreshAsync()
}

const isRecurrent = ref(true)

const paySubscription = async (sbp = false) => {
  const width = 850
  const height = 700

  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2

  paymentWindow = window.open(
    "",
    "subscription-payment-modal",
    `width=${width},height=${height},left=${left},top=${top}`
  ) as Window | null

  if (!paymentWindow) {
    return
  }

  try {
    await getSubscriptionPaymentLink(
      selectedSubscription.value?.id as number,
      isRecurrent.value,
      sbp
    )

    paymentWindow.location.href = paymentLink.value?.data?.card as string
  } catch (error) {
    paymentWindow.close()
    paymentWindow = null
  }
}

const receiveMessage = async (message: MessageEvent) => {
  if (message.data.mdOrder === null) {
    push.error({
      message: "Ошибка авторизации"
    })
  }
  if (typeof message !== "object" || !message.data || !message.data.mdOrder) {
    return
  }
  await requestSubscriptionApprove(message.data.mdOrder)
  if (subscriptionApprove?.value?.success) {
    await getCurrentSubscription()
    push.success({
      message: "Подписка успешно оплачена"
    })
    open.value = false
    await refreshAsync()
  } else {
    push.error({
      message: "Ошибка оплаты"
    })
  }
}

onMounted(() => {
  window.addEventListener("message", receiveMessage, false)
})

onUnmounted(() => {
  window.removeEventListener("message", receiveMessage, false)
})

const [showHelper, toggleShowHelper] = useToggle(false)

const isAdoptable = useAdoptable()

const selectedTariff = ref<string>("PRO")

const changeTariff = (tariff: string) => (selectedTariff.value = tariff)

const discountOfTariff = computed(() => {
  return (discont?: string) => {
    if (!discont) return
    return `(скидка ${discont})`
  }
})

const isPro = computed(() => subscriptionCurrentData.value?.data.product.name === "PRO")

const computedClasses = computed(() => (isPro.value ? "mt12" : "mt32"))
</script>

<template>
  <SubscriptionReminder always :style="{ marginTop: '0', marginBottom: 0 }" />
  <ARow :gutter="[24, 24]" :style="{ marginTop: '24px' }">
    <template v-if="SubscriptionCurrentPending || SubscriptionsPending">
      <ACol
        v-for="(item, key) in [...Array(3).keys()]"
        :key="key"
        class="gutter-row"
        :xs="12"
        :lg="8"
      >
        <ASkeletonButton class="skeleton" active block />
      </ACol>
    </template>

    <template v-else>
      <ACol v-if="!isAdoptable" class="sub-container" :span="24">
        <ACol class="gutter-row" :span="24" :gap="14">
          <div class="subscription-row">
            <AText :style="{ fontWeight: 700 }">Преимущества:</AText>
          </div>
          <template v-for="benefit in list" :key="benefit">
            <div class="subscription-row">
              <AText class="benefit">{{ benefit }}</AText>
            </div>
          </template>
        </ACol>
        <div class="sub-items-container">
          <div class="sub-item">
            <div class="sub-card">
              <AText>ФРИ</AText>
            </div>
            <template v-for="(_, key) in [...Array(12).keys()]" :key="key">
              <CheckCircleOutlined
                v-if="key < 6"
                :class="key === 0 ? 'icon start' : 'icon'"
                :style="{
                  color: 'var(--incoming-operation-color)'
                }"
              />
              <CloseCircleOutlined
                v-else
                class="icon"
                :style="{ color: 'var(--outgoing-operation-color)' }"
              />
            </template>
            <div
              v-if="!isPro"
              :style="{
                marginTop: '30px',
                height: '40px',
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <ATypographyText
                :style="{
                  fontSize: '14px',
                  textAlign: 'center',
                  color: 'var(--subscription-current-sub)'
                }"
                >Текущая подписка</ATypographyText
              >
            </div>
          </div>
          <div class="sub-item">
            <div class="sub-card blue">
              <AText>ПРО</AText>
              <small>от 540₽ в месяц</small>
            </div>
            <CheckCircleOutlined
              v-for="(_, key) in [...Array(12).keys()]"
              :key="key"
              :class="key === 0 ? 'icon start' : 'icon'"
              :style="{
                color: 'var(--incoming-operation-color)'
              }"
            />
            <AFlex
              v-if="isPro"
              vertical
              align="center"
              :style="{ marginTop: '22px', textAlign: 'center' }"
            >
              <ATypographyText :style="{ fontSize: '14px' }">Текущая подписка</ATypographyText>
              <ATypographyText type="secondary" :style="{ fontSize: '12px' }"
                >Функции ПРО будут доступны по {{ finishDate }}</ATypographyText
              >
              <AButton
                v-if="!isFinish"
                class="subscription-cancel"
                type="link"
                danger
                @click="openConfirmModal"
              >
                Отменить подписку
              </AButton>
            </AFlex>
            <AButton
              v-if="isCanPayment"
              block
              type="primary"
              size="large"
              class="subscription-status"
              :class="computedClasses"
              @click="openModal('PRO')"
            >
              Подключить
            </AButton>
          </div>
        </div>
      </ACol>
      <ACol v-else class="sub-container" :span="24">
        <AFlex :gap="16" :style="{ marginBottom: '24px' }" align="center">
          <div class="sub-card" @click="changeTariff('FREE')">
            <AText>ФРИ</AText>
          </div>
          <div class="sub-card blue" @click="changeTariff('PRO')">
            <AText>ПРО</AText>
            <small>от 540₽ в месяц</small>
          </div>
        </AFlex>
        <ACol class="gutter-row" :span="24" :gap="14">
          <div class="subscription-row">
            <AText :style="{ fontSize: '24px', fontWeight: 700 }">Преимущества:</AText>
          </div>
          <template v-for="(benefit, key) in list" :key="benefit">
            <div class="subscription-row">
              <AText class="benefit">{{ benefit }}</AText>
              <template v-if="selectedTariff === 'FREE'">
                <CheckCircleOutlined
                  v-if="key < 6"
                  :style="{
                    fontSize: '24px',
                    color: 'var(--incoming-operation-color)'
                  }"
                />
                <CloseCircleOutlined
                  v-else
                  :style="{ fontSize: '24px', color: 'var(--outgoing-operation-color)' }"
                />
              </template>
              <CheckCircleOutlined
                v-else
                :style="{ fontSize: '24px', color: 'var(--incoming-operation-color)' }"
              />
            </div>
          </template>
          <template v-if="selectedTariff === 'FREE'">
            <div
              v-if="!isPro"
              :style="{
                marginTop: '30px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }"
            >
              <ATypographyText
                :style="{ fontSize: '14px', color: 'var(--subscription-current-sub)' }"
                >Текущая подписка</ATypographyText
              >
            </div>
          </template>
          <template v-else>
            <AFlex
              v-if="isPro"
              vertical
              align="center"
              :style="{ marginTop: '22px', textAlign: 'center' }"
            >
              <ATypographyText :style="{ fontSize: '14px' }">Текущая подписка</ATypographyText>
              <ATypographyText type="secondary" :style="{ fontSize: '14px' }"
                >Функции ПРО будут доступны по {{ finishDate }}</ATypographyText
              >
              <AButton
                v-if="!isFinish"
                class="subscription-cancel"
                type="link"
                danger
                @click="openConfirmModal"
              >
                Отменить подписку
              </AButton>
            </AFlex>
            <AButton
              v-if="isCanPayment"
              block
              type="primary"
              size="large"
              class="subscription-status"
              :class="computedClasses"
              @click="openModal('PRO')"
            >
              Подключить
            </AButton>
          </template>
        </ACol>
      </ACol>
    </template>
  </ARow>
  <AModal v-model:open="open" :style="{ width: '555px' }" :footer="null">
    <template #title>
      <AFlex
        :gap="12"
        align="center"
        :style="{
          paddingBottom: '16px',
          borderBottom: '1px solid var(--subscription-modal-border)'
        }"
      >
        <ATypographyTitle :style="{ margin: 0 }" :level="3"> Подключить подписку </ATypographyTitle>
        <SubscriptionBadge
          :style="{ textTransform: 'uppercase', fontWeight: '400' }"
          size="large"
          :subscription="modalSub"
        />
      </AFlex>
    </template>
    <AFlex :gap="16" vertical>
      <AText sm :style="{ fontWeight: 700 }">Период</AText>
      <ASelect v-model:value="selectedSubscriptionId" style="width: 100%">
        <ASelectOption v-for="option in subscriptions" :key="option.id" :value="option.id">
          <span
            >{{ option.period }}
            <span :style="{ color: token.colorPrimary }">{{
              discountOfTariff(option.marketing_discount)
            }}</span></span
          >
        </ASelectOption>
      </ASelect>
    </AFlex>
    <Divider :style="{ width: '100%', margin: '16px 0', borderBlockStartWidth: '2px' }" />
    <ListBlock :list="list" :style="{ marginTop: '24px' }" />
    <Divider :style="{ width: '100%', margin: '16px 0', borderBlockStartWidth: '2px' }" />
    <AFlex justify="space-between" align="center">
      <ATypographyTitle :style="{ margin: 0 }" :level="4"> Стоимость подписки </ATypographyTitle>
      <ATypographyTitle :style="{ margin: 0 }" :level="4">{{
        selectedSubscription?.name === "PRO" ? `${selectedSubscription?.cost} рублей` : "Бесплатно"
      }}</ATypographyTitle>
    </AFlex>
    <template v-if="isChangingOnFree">
      <AButton
        block
        type="primary"
        size="large"
        :disabled="isFinish"
        :style="{ marginTop: '16px' }"
        :loading="paymentLinkPending"
        @click="openConfirmModal"
      >
        {{ isFinish ? "Оформлена" : "Оформить" }}
      </AButton>
    </template>
    <template v-else>
      <AFlex>
        <ACheckbox v-model:checked="isRecurrent" :style="{ marginTop: '16px' }"
          >Автоматически продлевать подписку</ACheckbox
        >
      </AFlex>
      <AButton
        block
        size="large"
        :style="{
          width: '100%',
          marginTop: '8px',
          padding: 0,
          borderWidth: '2px',
          borderColor: token.colorPrimary
        }"
        :loading="paymentLinkPending"
        @click="() => paySubscription(true)"
      >
        <div style="display: inline-flex; align-items: center; gap: 8px">
          <span>Оплатить c </span><SbpIcon :height="24" />
        </div>
      </AButton>
      <AButton
        block
        type="primary"
        size="large"
        :style="{ marginTop: '8px' }"
        :loading="paymentLinkPending"
        @click="() => paySubscription()"
      >
        Оплатить
      </AButton>
      <ATypographyText
        :style="{
          display: 'inline-block',
          marginTop: '8px',
          fontSize: '12px',
          textAlign: 'center'
        }"
        >При оплате через SberPay автоматическое продление подписки не активируется</ATypographyText
      >
    </template>
    <div class="modal-footer">
      <Divider :style="{ width: '100%', margin: '16px 0', borderBlockStartWidth: '2px' }" />
      <ATypographyText :style="{ display: 'inline-block', fontSize: '12px', textAlign: 'center' }"
        >Нажимая кнопку "Оплатить", Вы принимаете:</ATypographyText
      >
      <div class="modal-links">
        <ATypographyLink
          href="/docs/SAMO_privacy_policy.pdf"
          target="_blank"
          :style="{ fontSize: '12px' }"
          >Политика конфиденциальности,
        </ATypographyLink>
        <ATypographyLink href="/docs/SAMO_terms.pdf" target="_blank" :style="{ fontSize: '12px' }"
          >Пользовательское соглашение,
        </ATypographyLink>
        <ATypographyLink href="/docs/SAMO_details.pdf" target="_blank" :style="{ fontSize: '12px' }"
          >Реквизиты компании
        </ATypographyLink>
        <Divider :style="{ width: '100%', margin: '16px 0', borderBlockStartWidth: '2px' }" />
        <ATypographyText
          type="secondary"
          :style="{ cursor: 'pointer' }"
          @click="toggleShowHelper(!showHelper)"
          ><InfoCircleOutlined :style="{ marginRight: '8px' }" />Оплатили по реквизитам?
        </ATypographyText>
        <template v-if="showHelper">
          <AText
            :style="{
              textAlign: 'center',
              fontSize: '13px',
              color: 'var(--subscription-modal-helper)'
            }"
            >Если вы оплатили по реквизитам - просим вас направить платежное поручение на почту:
            <ATypographyLink href="mailto:hello@samo.business">hello@samo.business</ATypographyLink
            >. Заказ обрабатывается до 5 дней
          </AText>
        </template>
      </div>
    </div>
  </AModal>
  <AModal v-model:open="openConfirm" :footer="null">
    <ATypographyTitle :style="{ margin: 0 }" :level="3">
      Вы уверены, что хотите отключить подписку ПРО?
    </ATypographyTitle>
    <ATypographyTitle :style="{ marginTop: '16px' }" :level="5">
      Функции ПРО будут доступны по {{ finishDate }}
    </ATypographyTitle>
    <ARow :gutter="[24, 24]">
      <ACol :xs="12" :xl="12">
        <AButton
          block
          type="primary"
          size="large"
          :style="{ marginTop: '20px' }"
          @click="changeSubscription"
        >
          Да
        </AButton>
      </ACol>
      <ACol :xs="12" :xl="12">
        <AButton block size="large" :style="{ marginTop: '20px' }" @click="openConfirm = false">
          Отмена
        </AButton>
      </ACol>
    </ARow>
  </AModal>
</template>

<style>
.mt12 {
  margin-top: 12px;
}
.mt32 {
  margin-top: 32px;
}
.sub-items-container {
  position: absolute;
  top: 0;
  right: 10%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  max-width: 800px;
  @media (max-width: 1730px) {
    right: 0%;
    max-width: 50%;
  }
  @media (max-width: 1440px) {
    max-width: 60%;
  }
  @media (max-width: 1160px) {
    max-width: 52%;
  }
}
.sub-item {
  flex-basis: 50%;
  max-width: 320px;
  height: fit-content;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 16px;

  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-child(1) {
    padding-bottom: 14px;
    border: 1px solid var(--subscription-card-border);
  }
  &:nth-child(2) {
    border: 1px solid var(--transfer-operation-color);
    background-color: rgba(230, 242, 255, 0.5);
  }
  @media (max-width: 1580px) {
    flex-basis: 45%;
  }
  @media (max-width: 1440px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.sub-card {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  background: linear-gradient(90deg, #969696 0%, #323231 100%);

  @media (max-width: 1580px) {
    height: 48px;
  }

  & span {
    text-align: center;
    font-size: 28px;
    line-height: normal;
    color: #fff;
    text-transform: uppercase;
    @media (max-width: 1580px) {
      font-size: 20px;
    }
  }
  & small {
    color: #ffffffcc;
    font-size: 14px;
    @media (max-width: 1580px) {
      font-size: 12px;
    }
  }
  &.blue {
    background: linear-gradient(90deg, #5297ff 0%, #0041d9 100%);
  }
  @media (max-width: 1024px) {
    cursor: pointer;
  }
}
.sub-container {
  position: relative;
  max-width: 1920px;
  padding-top: 44px;
  padding-bottom: 116px;
  border-bottom: 1px solid var(--subscription-row-border);
  @media (max-width: 1440px) {
    padding-bottom: 134px;
  }
  @media (max-width: 1023px) {
    padding-top: 0;
    padding-bottom: 69px;
    border: none;
  }
}

.subscription-row {
  width: 100%;
  border-bottom: 1px solid var(--subscription-row-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  &:first-child {
    & span {
      font-size: 24px;
      @media (max-width: 1580px) {
        font-size: 20px;
      }
    }
    padding-bottom: 8px;
  }
  &:not(:first-child) {
    padding: 8px 0;
  }
}

.benefit {
  font-size: 20px;
  @media (max-width: 1580px) {
    font-size: 15px;
  }
}

.icon {
  font-size: 24px;
  padding-top: 24.2px;
  &.start {
    padding-top: 37.5px;
  }
  @media (max-width: 1580px) {
    font-size: 18px;
    padding-top: 22.3px;
  }
}

.subscription-status {
  width: 100%;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 12px;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skeleton > span {
  height: 648px !important;
}

.subscription-cancel {
  display: inline-block;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  height: auto;
  & span {
    text-decoration: underline;
  }
}
</style>
