<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onUnmounted, type PropType, ref } from "vue"
import { useDebounceFn } from "@vueuse/core"
import { onMounted, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import {
  type OperationCategoryEdit,
  useOperationCategoriesStore
} from "@/modules/operation-category"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"
import { OperationCategoryTreeSelect } from "@/modules/operation-category"

import CategoryTypeSelect from "../CategoryTypeSelect.vue"
import BooleanSelect from "../BooleanSelect.vue"

import { useRuleset } from "./use-ruleset"
import { useFormState } from "./use-form-state"

const props = defineProps({
  operationCategory: {
    type: [Object, undefined] as PropType<OperationCategoryEdit | undefined>,
    default: () => undefined
  }
})
const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const resetAction = useDebounceFn(() => {
  if (props.operationCategory) {
    Object.assign(formState, props.operationCategory)
  }
}, 100)
watch(() => props.operationCategory, resetAction)
onMounted(resetAction)

const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const operationsStore = useOperationCategoriesStore()
const { isEditing, editError, isEditingFinished } = storeToRefs(operationsStore)

watch([editError, isEditingFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isEditingFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)

const submitAction = async () => {
  try {
    await validate()
    if (props.operationCategory) {
      await operationsStore.editOperationCategory(requestData.value)
    } else {
      throw new Error("Cannot save operationCategory")
    }
    push.success({
      message: "Статья изменена"
    })
    emit("success")
    operationsStore.updateBufferAfterEdit(requestData.value)
  } catch (err) {
    if (isValidateError(err)) {
      operationsStore.isEditing = false
      formRef.value?.scrollToField(err.errorFields[0].name, {
        block: "start",
        boundary: (parent: HTMLElement) => {
          parent.focus()
          return parent.classList.contains("ant-row")
        }
      })
    }
  }
}

defineExpose({
  submitAction,
  isLoading: computed(() => isEditingFinished.value || isEditing.value || isValidating.value),
  resetAction
})
const popupContainerGetter = usePopupContainerGetter()

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-operation-categories-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <AFormItem
      label="Название"
      name="name"
      v-bind="validateInfos?.name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem label="Родительская статья" name="parent_id" v-bind="validateInfos?.parent_id">
      <OperationCategoryTreeSelect
        v-model="formState.parent_id"
        :multiple="false"
        :action-type="operationsStore.actionType"
        :exception-id="props.operationCategory?.id"
        size="large"
        hide-link
        placeholder="Выберите статью"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Тип" name="type" v-bind="validateInfos?.type">
      <CategoryTypeSelect
        v-model:value="formState.type"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Облагается налогом" name="type">
      <BooleanSelect
        v-model:value="formState.is_taxable"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Комментарий" name="comment" v-bind="validateInfos?.comment">
      <ATextarea v-model:value="formState.comment" placeholder="Введите комментарий" :rows="3" />
    </AFormItem>
  </AForm>
</template>
