import { useRequest } from "vue-request"

import { client, useApiError } from "@/package/api-client"
import type { LegalEntitySummary } from "@/modules/legal-entity"

interface LegalEntityResponse {
  success: boolean
  data: LegalEntitySummary[]
}

const useLegalNameListRequest = () =>
  client.get<LegalEntityResponse>("/legal-entities/list-legal-name").then(({ data }) => data.data)

export const useLegalNameList = () => {
  const { data, error, loading, runAsync } = useRequest<LegalEntitySummary[]>(
    () => useLegalNameListRequest(),
    {
      cacheKey: "/legal-entities/list-legal-name"
    }
  )

  return {
    legalNameListResponse: data,
    requestLegalNameList: runAsync,
    islegalNameListLoading: loading,
    legalNameListError: useApiError(error)
  }
}
