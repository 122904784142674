<script setup lang="ts">
import { ref, watch } from "vue"
import { Form, theme, Menu, MenuItem, MenuDivider, Dropdown } from "ant-design-vue"
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue"
import { get } from "@vueuse/core"

import {
  ReportType,
  TaxRateSelect,
  UsnType,
  UsnTypeSelect,
  useReportsStore,
  type ReportDownloadData
} from "@/modules/reports"
import { useForm } from "@/package/hooks"
import { InfoModal } from "@/package/ui-kit"

import { useRuleset } from "./use-ruleset"

defineProps({
  open: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["close"])
const store = useReportsStore()

const formState = ref<Partial<ReportDownloadData>>({
  tax: null,
  usnType: null,
  quarter: "",
  year: "",
  nineMonths: "",
  halfYear: "",
  loss: ""
})

const ruleset = useRuleset()

const { validate, validateInfos, clearValidate, resetFields } = useForm(formState, ruleset)

const { token } = theme.useToken()

const loadingState = ref<boolean>(false)

const downloadReport = (format: string) => {
  const type: ReportType =
    formState.value.usnType === UsnType.income ? ReportType.usn : ReportType.usn_minus_expenses
  loadingState.value = true
  const filteredData = Object.assign(
    {},
    ...Object.keys(formState.value)
      .filter(
        (key) =>
          key !== "usnType" &&
          formState.value[key as keyof ReportDownloadData] !== null &&
          formState.value[key as keyof ReportDownloadData] !== ""
      )
      .map((key) => ({ [key]: formState.value[key as keyof ReportDownloadData] }))
  )
  clearValidate()
  return validate()
    .then(() => {
      store.downloadReportUsn({ type, format, ...get(filteredData) }).finally(() => {
        resetFields()
        emit("close")
        loadingState.value = false
      })
    })
    .catch(() => (loadingState.value = false))
}

watch(
  () => formState.value,
  (val) => {
    const { usnType, quarter, year, nineMonths, halfYear } = val

    if (usnType && [quarter, year, nineMonths, halfYear].every((rate) => rate === "")) {
      const interestRate = usnType === UsnType.income ? "6" : "15"

      Object.assign(formState.value, {
        quarter: interestRate,
        year: interestRate,
        nineMonths: interestRate,
        halfYear: interestRate
      })
    }
  },
  { deep: true }
)

const close = () => {
  clearValidate()
  resetFields()
  emit("close")
}

const showAnnotationModal = () =>
  InfoModal({
    content:
      "Сумма убытка, полученного в предыдущем налоговом периоде (периодах), уменьшающая налоговую базу за налоговый период",
    title: "Дополнительная информация"
  })
</script>

<template>
  <APageDrawer :open="open" title="Данные для отчета УСН" @close="close">
    <Form
      name="usn-report-form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      :style="{ marginTop: '12px' }"
      scroll-to-first-error
    >
      <AFlex vertical :gap="16">
        <ATypographyText type="secondary"
          ><InfoCircleOutlined :style="{ marginRight: '8px' }" />Отчет УСН можно сформировать только
          за календарный год</ATypographyText
        >
        <ATypographyText type="secondary"
          ><strong>УСН "Доходы"</strong> <br />
          Стандартная процентная ставка УСН "Доходы" — 6%.<br />
          Повышенная процентная ставка УСН "Доходы" — 8%, если ваш доход больше 150 млн. рублей, но
          меньше 200 млн. рублей и (или) ваша средняя численность работников больше 100 человек, но
          меньше 130 человек.
        </ATypographyText>
        <ATypographyText type="secondary"
          ><strong>УСН "Доходы минус расходы"</strong><br />
          Стандартная процентная ставка — 15%.
        </ATypographyText>
      </AFlex>
      <ADivider :style="{ marginTop: '24px', marginBottom: '24px' }" />
      <AFormItem label="Тип отчета УСН" name="usnType" v-bind="validateInfos?.usnType">
        <UsnTypeSelect v-model:value="formState.usnType" size="large" />
      </AFormItem>
      <AFormItem
        label="Код признака применения налоговой ставки"
        name="tax"
        v-bind="validateInfos?.tax"
      >
        <TaxRateSelect v-model:value="formState.tax" size="large" />
      </AFormItem>
      <AText sm :style="{ fontWeight: 700, marginBottom: '16px' }">Налоговая ставка (%)</AText>
      <AFormItem
        label="За первый квартал"
        name="quarter"
        :style="{ marginTop: '16px' }"
        v-bind="validateInfos?.quarter"
      >
        <AInput v-model="formState.quarter" size="large" placeholder="Введите налоговую ставку" />
      </AFormItem>
      <AFormItem label="За полугодие" name="halfYear" v-bind="validateInfos?.halfYear">
        <AInput v-model="formState.halfYear" size="large" placeholder="Введите налоговую ставку" />
      </AFormItem>
      <AFormItem label="За девять месяцев" name="nineMonths" v-bind="validateInfos?.nineMonths">
        <AInput
          v-model="formState.nineMonths"
          size="large"
          placeholder="Введите налоговую ставку"
        />
      </AFormItem>
      <AFormItem label="За налоговый период" name="year" v-bind="validateInfos?.year">
        <AInput v-model="formState.year" size="large" placeholder="Введите налоговую ставку" />
      </AFormItem>
      <template v-if="formState.usnType === UsnType.expense">
        <AText sm :style="{ fontWeight: 700, marginBottom: '16px' }"
          >Дополнительна информация</AText
        >
        <AFormItem name="loss" v-bind="validateInfos?.loss" :style="{ marginTop: '16px' }">
          <AText>
            Сумма убытка, полученная к предыдущем налоговом периоде
            <QuestionCircleOutlined
              :style="{ color: token.colorPrimary, marginLeft: '4px' }"
              @click="showAnnotationModal"
          /></AText>

          <AInput v-model="formState.loss" size="large" placeholder="Введите сумму убытка" />
        </AFormItem>
      </template>
    </Form>
    <template #footer>
      <Dropdown :trigger="['click']">
        <AButton :loading="loadingState" block type="primary" size="large">Скачать отчёт</AButton>
        <template #overlay>
          <Menu>
            <MenuItem key="xml" @click="downloadReport('xml')">
              <span>Скачать XML</span>
            </MenuItem>
            <MenuDivider />
            <MenuItem key="pdf" @click="downloadReport('pdf')">
              <span>Скачать PDF</span>
            </MenuItem>
          </Menu>
        </template>
      </Dropdown>
    </template>
  </APageDrawer>
</template>
